<template>
  <vx-card class="financial-setting" :class="[{'p-2 h-full': $route.name === 'financialSetting'}]" no-shadow>

    <div class="financial-setting-fields">
      <h6 class="h5 text-primary">{{ $t('setting.financial.wage') }}</h6>
      <vs-divider class="my-2"></vs-divider>

      <custom-price-input class="w-full mb-2"
                          :label="$t('setting.financial.labels.cardToCardWage')"
                          is-empty
                          v-model="financial.cardToCardWage"/>

      <custom-price-input class="w-full mb-2"
                          :label="$t('setting.financial.labels.ibanWage')"
                          is-empty
                          v-model="financial.ibanWage"/>
    </div>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button v-show="false"
                 id="editFinancialSettingBTN"
                 @click="sendData"/>
    </div>
  </vx-card>
</template>

<script>
import axios from "axios";
import {editFinancialSetting, getSetting} from "../../../../http/requests/settings/setting";
import CustomValidateInput from "../../../../components/customInput/customValidateInput";
import {hideLoading, showLoading} from "../../../../assets/js/functions";
import CustomNumberInput from "../../../../components/customInput/customNumberInput.vue";
import CustomPriceInput from "@/components/customInput/customPriceInput.vue";

export default {
  name: 'FinancialSetting',
  components: {CustomPriceInput, CustomNumberInput, CustomValidateInput},
  metaInfo () {
    return {
      title: this.$t('setting.financial.title')
    }
  },
  data () {
    return {
      negativeValue: false,
      financial: {
        cardToCardWage: {
          value: '',
          isValid: true
        },
        ibanWage: {
          value: '',
          isValid: true
        }
      },
      actions: {
        toolbar: [
          {
            id: 'editFinancialSettingBTN',
            icon: 'SAVE',
            iconPack: 'useral',
            color: 'success'
          }
        ],
        leftToolbar: [
          {
            id: 'routeBackBTN',
            route: {name: 'Settings'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.user
    }
  },
  created () {
    // showLoading()
    if (this.$route.name === 'Settings') {
      this.actions.leftToolbar.splice(0, 1)
    }
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
    }, 50)
    this.$store.dispatch('setPageTitle', this.$t('setting.financial.title'))

    this.getFinancialSetting()
  },

  methods: {
    getFinancialSetting() {
      getSetting().then(response => {
        const app = response.data.data.financial
        this.financial.cardToCardWage.value = app.card_to_card_wage || ''
        this.financial.ibanWage.value = app.iban_wage || ''
        hideLoading()
      })
          .catch(() => {
            hideLoading()
          })
    },
    sendData() {
      if (!this.financial.ibanWage.isValid ||
          !this.financial.cardToCardWage.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('setting.financial.notifications.wrongValues'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 5000
        })
        return false
      }

      const payload = {
        card_to_card_wage: this.financial.cardToCardWage.value,
        iban_wage: this.financial.ibanWage.value
      }
      editFinancialSetting(payload).then(response => {
        this.$vs.notify({
          time: 2400,
          title: this.$t('alert.message.title'),
          text: this.$t('setting.financial.notifications.edit.success'),
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })
        if (this.$route.name === 'financialSetting') {
          this.$router.push({name: 'Settings'})
        } else {
          this.$emit('edited')
        }
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {

          switch (error.response.status) {
            case 406:
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('setting.financial.notifications.negativeBalanceError'),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 5000
              })
              break

            default:
              this.$vs.notify({
                time: 2400,
                title: this.$t('alert.error.title'),
                text: this.$t('setting.financial.notifications.edit.error'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
              break
          }
        }
      })
    },
  }
}
</script>

<style lang="scss">
.financial-setting {
  .vx-card__body {
    padding: 0 !important;
  }

  .financial-setting-fields {
    max-width: 400px;
  }
}
</style>
